<template>
  <div class="home">
    <div class="module-title main">360智能企业服务平台</div>
    <div class="content">
      <div class="text-card">
        <span>借助AI能力，全面提升企业的服务质量和效率</span>
      </div>
      <div class="content-bg">
        <div class="back-cricle0">
          <div class="back-title">员工福利</div>
          <transition name="expand">
            <div class="back-text">
              <div class="item-ele-top">
                <div class="ele-title">员工福利</div>
              </div>
              <img class="ele-icon" src="https://p2.ssl.qhimg.com/t11098f6bcddb9ab00bee468d8f.png" height="90" width="90">
              <div class="item-ele-main">
                <p>员工关怀一站式服务平台</p>
              </div>
              <div class="item-ele-content">
                <span>员工内购</span>
                <span>福利发放</span>
                <span>灵活结算</span>
              </div>
              <a href="https://6360.cn/" target="_blank">
                <div class="item-ele-bottom">查看详情</div>
              </a>
            </div>
          </transition>
        </div>
        <div class="back-cricle1">
          <div class="back-title">积分激励</div>
          <div class="back-text">
            <div class="item-ele-top">
              <div class="ele-title">积分激励</div>
            </div>
            <img class="ele-icon" src="https://p2.ssl.qhimg.com/t11098f6bcddb9ab00bee468d8f.png" height="90" width="90">
            <div class="item-ele-main">
              <p>企业积分服务平台</p>
            </div>
            <div class="item-ele-content">
              <span>价值奖励</span>
              <span>预算掌控</span>
              <span>统一结算</span>
            </div>
            <a href="https://6360.cn/" target="_blank">
              <div class="item-ele-bottom">查看详情</div>
            </a>
          </div>
        </div>
        <div class="back-cricle2">
          <div class="back-title">幕印培训</div>
          <div class="back-text">
            <div class="item-ele-top">
              <div class="ele-title">幕印培训</div>
            </div>
            <img class="ele-icon" src="https://p2.ssl.qhimg.com/t11098f6bcddb9ab00bee468d8f.png" height="90" width="90">
            <div class="item-ele-main">
              <p>全场景直播服务平台</p>
            </div>
            <div class="item-ele-content">
              <span>在线直播</span>
              <span>内容管理</span>
            </div>
            <a href="https://muyin.360.cn/" target="_blank">
              <div class="item-ele-bottom">查看详情</div>
            </a>
          </div>
        </div>
        <div class="back-cricle3">
          <div class="back-title">智能营销</div>
          <div class="back-text" style="width:336px">
            <div class="item-ele-top">
              <div class="ele-title">智能营销</div>
            </div>
            <img class="ele-icon" src="https://p2.ssl.qhimg.com/t11098f6bcddb9ab00bee468d8f.png" height="90" width="90">
            <div class="item-ele-main">
              <p>新一代智能客服平台</p>
            </div>
            <div class="item-ele-content">
              <span>问答机器人</span>
              <span>智能外呼</span>
              <span>CRM</span>
              <span>人工服务</span>
            </div>
            <a href="https://zhike.360.cn/" target="_blank">
              <div class="item-ele-bottom">查看详情</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="scss" scoped>
@keyframes my-animation {
  0% {
    background-position-x: 0px;
  }

  2.56% {
    background-position-x: -120px;
  }

  5.13% {
    background-position-x: -240px;
  }

  7.69% {
    background-position-x: -360px;
  }

  10.26% {
    background-position-x: -480px;
  }

  12.82% {
    background-position-x: -600px;
  }

  15.38% {
    background-position-x: -720px;
  }

  17.95% {
    background-position-x: -840px;
  }

  20.51% {
    background-position-x: -960px;
  }

  23.08% {
    background-position-x: -1080px;
  }

  25.64% {
    background-position-x: -1200px;
  }

  28.21% {
    background-position-x: -1320px;
  }

  30.77% {
    background-position-x: -1440px;
  }

  33.33% {
    background-position-x: -1560px;
  }

  35.90% {
    background-position-x: -1680px;
  }

  38.46% {
    background-position-x: -1800px;
  }

  41.03% {
    background-position-x: -1920px;
  }

  43.59% {
    background-position-x: -2040px;
  }

  46.15% {
    background-position-x: -2160px;
  }

  48.72% {
    background-position-x: -2280px;
  }

  51.28% {
    background-position-x: -2400px;
  }

  53.85% {
    background-position-x: -2520px;
  }

  56.41% {
    background-position-x: -2640px;
  }

  58.97% {
    background-position-x: -2760px;
  }

  61.54% {
    background-position-x: -2880px;
  }

  64.10% {
    background-position-x: -3000px;
  }

  66.67% {
    background-position-x: -3120px;
  }

  69.23% {
    background-position-x: -3240px;
  }

  71.79% {
    background-position-x: -3360px;
  }

  74.36% {
    background-position-x: -3480px;
  }

  76.92% {
    background-position-x: -3600px;
  }

  79.49% {
    background-position-x: -3720px;
  }

  82.05% {
    background-position-x: -3840px;
  }

  84.62% {
    background-position-x: -3960px;
  }

  87.18% {
    background-position-x: -4080px;
  }

  89.74% {
    background-position-x: -4200px;
  }

  92.31% {
    background-position-x: -4320px;
  }

  94.87% {
    background-position-x: -4440px;
  }

  97.44% {
    background-position-x: -4560px;
  }

  100.00% {
    background-position-x: -4680px;
  }
}

.home {
  // width: 1920px;
  display: flex;
  position: relative;
  height: 1020px;
  flex-direction: column;
  align-items: center;
  background: #F2F9FF;


  .content {
    width: $container-width;
    height: 734px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background: url('https://p2.ssl.qhimg.com/t11098f6bcd25788df8e9e5ad25.png') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .main {
    font-size: 52px;
    line-height: 105%;
    margin-top: 144px;
    margin-bottom: 24px;
  }

  .text-card {
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    background-image: url('https://p1.ssl.qhimg.com/t11098f6bcdd577077d19a7462b.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 30px;

    // width:398px;
    span {
      color: rgba(255, 255, 255, 0.65);
      font-size: 20px;
      font-weight: 500;
      line-height: 36px;
      background: linear-gradient(180deg, #E7FEFF 20%, #FFF 66.04%, #7DB9FF 86.25%);
      background-clip: text;
      -webkit-background-clip: text;
      text-shadow: 0px 4px 6px #0d60c24d;
    }
  }

  .back-title {
    display: inline-flex;
    height: 30px;
    width: 80px;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(10px);
    color: #1B2532;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    /* 150% */
    position: absolute;
  }

  .back-cricle0 {
    top: 40.04%;
    left: 11.11%;
    position: absolute;



    .back-title {
      left: 75px;
      top: 55px;
    }
  }

  .back-cricle1 {
    top: 62.00%;
    left: 25.89%;
    position: absolute;


    .back-title {
      left: -82px;
      top: 49px;
    }
  }

  .back-cricle2 {
    top: 61.56%;
    right: 6.15%;
    position: absolute;


    .back-title {
      left: 54px;
      top: 76px;
    }
  }

  .back-cricle3 {
    top: 35.89%;
    right: 5.47%;
    position: absolute;

    .back-title {
      top: 62px;
      left: -77px;
    }
  }

  .back-cricle0,
  .back-cricle1,
  .back-cricle2,
  .back-cricle3 {
    width: 130px;
    height: 130px;
    z-index: 3;
    animation: my-animation 1.6s steps(1) infinite;
    background-image: url('https://p3.ssl.qhimg.com/t11098f6bcdb43bb4987c33763f.png');
    cursor: pointer;
  }

  .back-text {
    display: none;
    z-index: 3;
    position: absolute;
    top: -65px;
    left: -130px;
    right: -20px;
    width: 296px;
    padding: 24px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid #FFF;
    background: url('https://p5.ssl.qhimg.com/t11098f6bcd2f705ea05d996815.jpg');
    background-size: cover;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-out;
  }

  @keyframes expandHeight {
    from {
      height: 0;
      display: none;
      overflow: hidden;
    }

    to {
      height: 188px;
      display: block;
      overflow: hidden;
    }
  }

  .back-cricle0:hover .back-text,
  .back-cricle1:hover .back-text,
  .back-cricle2:hover .back-text,
  .back-cricle3:hover .back-text {
    display: block;
    animation: expandHeight 0.3s forwards;
  }


  .item-ele-top {
    display: flex;
    justify-content: space-between;
    /* Space between items */
    align-items: center;
    margin-bottom: 4px;

    .ele-title {
      flex: 1;
      color: #FFF;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }


  }

  .ele-icon {
    position: absolute;
    right: 11px;
    top: 24px;
    display: block;
    height: 90px;
    width: 90px;
  }

  .item-ele-main {
    p {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
    }
  }

  .item-ele-content {
    display: flex;
    direction: row;
    margin-top: 20px;
    margin-bottom: 24px;

    span {
      display: flex;
      height: 28px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.20);
      color: #FFF;
      font-size: 14px;
    }

    span:not(:first-child) {
      margin-left: 8px;
    }

  }

  .item-ele-bottom {
    display: flex;
    color: #3873FA;
    height: 40px;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 60px;
    background: #FFF;
    box-shadow: 0px 12px 40px 0px rgba(56, 115, 250, 0.30);
    cursor: pointer;
  }

}
</style>
