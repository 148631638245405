/*
 * @Author: yh
 * @Date: 2024-07-23 15:18:31
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-07-25 18:34:52
 * @FilePath: \enterprises-service\src\main.ts
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'reset-css';

createApp(App).use(router).mount('#app')
