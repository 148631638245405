<!--
 * @Author: yh
 * @Date: 2024-07-26 17:52:54
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-07-31 18:46:22
 * @FilePath: \enterprises-service\src\views\Home\home.vue
-->
<template>
  <Header />
  <Index id="index" />
  <Product id="product" />
  <Cases id="cases" />
  <Examples id="examples" />
  <Footer />
</template>
<script lang="ts" setup>
import Header from '@/components/Header/header.vue';
import Index from '@/views/Index/index.vue';
import Product from '@/views/Product/product.vue';
import Cases from '@/views/Cases/cases.vue';
import Examples from '@/views/Examples/examples.vue'
import Footer from '@/components/Footer/footer.vue';
import { useRouter, useRoute } from 'vue-router';
import {onMounted} from 'vue'

const router = useRouter();
const route = useRoute();

const scrollToSection = (hash: string) => {
  const element = document.querySelector(hash);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
    // 更新 URL 锚点
    router.replace({ ...route, hash });
  }
};

onMounted(() => {
  if (route.hash) {
    scrollToSection(route.hash);
  }
});
</script>
