/*
 * @Author: yh
 * @Date: 2024-07-23 15:18:31
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-07-26 18:16:58
 * @FilePath: \enterprises-service\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw  } from 'vue-router'
import HomeView from '../views/Home/home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
