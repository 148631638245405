<template>
  <div class="cases-box">
    <div class="module-title title">解决方案</div>
    <div class="cases-tab">
      <div class="cases-tab-item">
        <div :class="['cases-tab-title', activedId == item.id ? 'active' : '']" v-for="item in tabItem" :key="item.id"
          @click="activedId = item.id">
          <span v-html="item.svg"></span>
          {{ item.name }}
        </div>
      </div>
      <div class="cases-tab-content">
        <div class="cases-descbox" :style="{ 'background': `url(${cases[activedId].bg})`, 'background-size': '100% auto' }">
          <div class="cases-desc">
            <div class="cases-title">{{ cases[activedId].title }}</div>
            <div>{{ cases[activedId].desc }}</div>
          </div>
        </div>
        <div class="cases-step">
          <div class="cases-name">
            <div v-for="item in cases[activedId].step.name" :key="item.id"
              :style="{ 'background': `url(${item.bg})`, 'background-size': '100% 100%', 'color': item.color }">{{ item.name
              }}</div>
          </div>
          <div class="cases-func">
            <div  class="cases-func-item" v-for="item in cases[activedId].step.list" :key="item.id"
            :style="{ 'background': `${item.bg}`, 'color': item.color }">
              <div v-for="func in item.func" :key="func.id">{{ func }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
const tabItem = [
  {
    id: 1,
    name: '组织成长解决方案',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <rect opacity="0.2" width="20" height="20" fill="#A481FF"/>
  <circle opacity="0.8" cx="20" cy="20" r="10" fill="#A481FF"/>
  <g style="mix-blend-mode:multiply" opacity="0.8">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0001 10V20H10C10 14.4772 14.4772 10 20 10C20 10 20.0001 10 20.0001 10Z" fill="#A481FF"/>
  </g>
</svg>`},
  {
    id: 2,
    name: '智能营销解决方案',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <rect opacity="0.2" x="2.00049" width="18" height="22" fill="#FFB70F"/>
  <rect opacity="0.8" x="10.0005" y="8" width="18" height="22" fill="#FFB70F"/>
  <g style="mix-blend-mode:multiply" opacity="0.8">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0005 8V22H10.0005V8H20.0005Z" fill="#FFB70F"/>
  </g>
</svg>`},
]

const activedId = ref(1)

const cases = {
  1: {
    bg: 'https://p0.ssl.qhimg.com/t11098f6bcd4dc7afd0c5c37164.png',
    title: '组织成长解决方案',
    desc: 'AI结合幕印的全场景“学习+激励”解决方案，让培训更有效，并辅以学习奖励积分来兑换物品的形式进行员工关怀，实现组织的成长。 ',
    step: {
      name: [
        { id: 1, name: '前期策划', color: '#006BFF', bg: 'https://p1.ssl.qhimg.com/t11098f6bcdbe3ccffdda7ca35f.png' },
        { id: 2, name: '在线培训', color: '#07828B', bg: 'https://p2.ssl.qhimg.com/t11098f6bcd95edb9b2d6b8a6e1.png' },
        { id: 3, name: '监控保障', color: '#07828B', bg: 'https://p2.ssl.qhimg.com/t11098f6bcd95edb9b2d6b8a6e1.png' },
        { id: 4, name: '总结报告', color: '#A26D0A', bg: 'https://p2.ssl.qhimg.com/t11098f6bcdc4089703a2d209ad.png' },
        { id: 5, name: '员工激励', color: '#A26D0A', bg: 'https://p5.ssl.qhimg.com/t11098f6bcd47fe746a79d1bfa6.png' },
      ],
      list: [
        { id: 1, func: ['明确目标', '制定方案', '规划流程', '人员配备'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(186, 224, 255, 0.60) 129.27%)' },
        { id: 2, func: ['直播+录播', '多模板一键切换', '多种互动实时发起', '跨区域连麦互动'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(183, 244, 236, 0.60) 129.27%)' },
        { id: 3, func: ['视频流监控', '网络监控', '服务器监控', '评论把控'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(183, 244, 236, 0.60) 129.27%)' },
        { id: 4, func: ['可视化数据报告', '观看数据统计', '二次回放', 'AI活动总结'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(253, 244, 191, 0.60) 129.27%)' },
        { id: 5, func: ['积分奖励', '积分兑换商品', '专属积分官网' ], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(253, 244, 191, 0.60) 129.27%)' },
      ]
    }
  },
  2: {
    bg: 'https://p5.ssl.qhimg.com/t11098f6bcd3032124cfec835c5.png',
    title: '智能营销解决方案',
    desc: '以Agent为核心，赋能营销各环节，从客户获取、培养、转化、维护、数据洞察，全程AI优化，提升销售效率，打造新一代的智能营销系统。',
    step: {
      name: [
        { id: 1, name: '获客', color: '#07828B', bg: 'https://p0.ssl.qhimg.com/t11098f6bcd6cde5090aca6558b.png' },
        { id: 2, name: '潜客挖掘', color: '#A26D0A', bg: 'https://p2.ssl.qhimg.com/t11098f6bcdc4089703a2d209ad.png' },
        { id: 3, name: '转化', color: '#A2530A', bg: 'https://p2.ssl.qhimg.com/t11098f6bcd35af7ce35e9491d7.png' },
        { id: 4, name: '服务', color: '#006BFF', bg: 'https://p0.ssl.qhimg.com/t11098f6bcdd856fe9f34f91325.png' },
      ],
      list: [
        { id: 1, func: ['AI搜客', '线索留资', '访客追踪', 'B2B线索宝'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(183, 244, 236, 0.60) 129.27%)' },
        { id: 2, func: ['AI线索清洗', 'AI智能外呼', 'AI话术生成', '客户画像'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(253, 244, 191, 0.60) 129.27%)' },
        { id: 3, func: ['AI转人工', 'AI效果评估', '线索跟进', '漏斗分析'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(255, 228, 186, 0.60) 129.27%)' },
        { id: 4, func: ['客户维护', '咨询答疑', '7*24在线', '数据报表'], bg: 'linear-gradient(348deg, rgba(255, 255, 255, 0.60) -22.39%, rgba(186, 224, 255, 0.60) 129.27%)' },
      ]
    }
  },
}

</script>
<style lang="scss" scoped>@import './cases.scss'</style>
