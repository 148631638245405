<template>
  <div class="footer">
    <div class="part-list">
      <a href="https://muyin.360.cn/" target="_blank">幕印培训</a>
      <a href="https://zhike.360.cn/" target="_blank">智客营销</a>
      <a href="https://6360.cn/" target="_blank">积分服务</a>
      <a href="https://agent.360.cn/" target="_blank">Agent</a>
    </div>
    <div>版权所有：北京三六零智领科技有限公司</div>
    <div>
      Copyright©2023 360.CN All Rights Reserved 360互联网安全中心
      <a href="https://beian.miit.gov.cn/" target="_blank">京公网安备 11000002000006号 京ICP证080047号[京ICP备08010314号-6]</a>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footer {
  width: $container-width;
  margin: 0 auto;
  padding: 24px 0;
  color: #9EA7B8;
  text-align: center;
  font-size: 12px;
  line-height: 20px;

  .part-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    a {
      padding-left: 60px;
      padding-right: 60px;
      position: relative;

      &:hover {
        color: $main-color;
      }

      &::after {
        display: block;
        content: '';
        width: 1px;
        background: #9EA7B8;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;

        &::after {
          display: block;
          content: '';
          width: 0;
        }
      }
    }
  }
}
</style>
