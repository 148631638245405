<template>
  <div class="product-box">
    <div class="module-title">
      产品中心
    </div>
    <swiper :slides-per-view="3" :space-between="0" @slideChange="onSlideChange" class="my-swiper">
      <swiper-slide v-for="(item, index) in items" :key="index"
        :class="{ active: activeIndex === index, inactive: activeIndex !== index }" @mouseenter="setActiveIndex(index)">
        <div class="swiper-shadow">
          <div class="swiper-animation">
            <div class="swiper-item-bg">
              <div class="swiper-item-img" :style="getSlideBgIng(index, item.backgroundImage, item.inactiveBackgroundImage)">
                <div class="swiper-item">
                  <div class="item-content">
                    <div class="item-title">{{ item.title }}</div>
                    <div class="item-intr">{{ item.description }}</div>
                  </div>
                  <a v-if="activeIndex === index" class="item-btn-link" :href="item.href" style="margin-top: auto;">{{
                    item.buttonText }}</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
const items = ref([
  {
    title: '幕印培训',
    description: '致力于为企业提供企业培训、知识营销于一体的全场景直播SssS服务，助力客户完成数字化转型',
    buttonText: '查看详情',
    backgroundImage: 'https://p0.ssl.qhimg.com/t11098f6bcd5c4d75224826f091.png',
    href: 'https://muyin.360.cn/',
    inactiveBackgroundImage: 'https://p0.ssl.qhimg.com/t11098f6bcd5c192860a4e342a5.png'
  },
  {
    title: '智能营销',
    description: '智能客服一体化解决专家、帮助企业更要了解用户、服务用户、实现用户价值化',
    buttonText: '查看详情',
    backgroundImage: 'https://p0.ssl.qhimg.com/t11098f6bcd7129ad874e585606.png',
    href: 'https://zhike.360.cn/',
    inactiveBackgroundImage: 'https://p0.ssl.qhimg.com/t11098f6bcdd8f0132f2b5cd469.png'
  },
  {
    title: '积分奖励',
    description: '运用积分奖励机制，提升员工参与度，并结合效果激励来达到提升员工认知及组织成长的目标',
    buttonText: '查看详情',
    backgroundImage: 'https://p5.ssl.qhimg.com/t11098f6bcd08b23547a7da10e5.png',
    href: 'https://6360.cn/',
    inactiveBackgroundImage: 'https://p0.ssl.qhimg.com/t11098f6bcdd6329c80ee942da1.png'

  },
  {
    title: '员工福利',
    description: '用科技助力企业打造更有温度的职场，互联网+数字搭建化虚为实，让企业福利数字化展现',
    buttonText: '查看详情',
    backgroundImage: 'https://p2.ssl.qhimg.com/t11098f6bcd95f51335ab225324.png',
    href: 'https://6360.cn/',
    inactiveBackgroundImage: 'https://p0.ssl.qhimg.com/t11098f6bcd5d10e7e91497aea4.png'
  }
]);
const activeIndex = ref(0);
const setActiveIndex = (index: number) => {
  activeIndex.value = index;
};
const onSlideChange = (swiper: any) => {
  activeIndex.value = swiper.realIndex;
};
const getSlideBgIng = (index: number, backgroundImage: string ,inactiveBackgroundImage:string) => {
  if (activeIndex.value === index) {
    return {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
    };
  }
  return {
    backgroundImage: `url(${inactiveBackgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  }
}
</script>
<style scoped lang="scss">
.product-box {
  margin: 0 auto;
  height: 512px;
  padding: 100px 60px 120px 60px;
  background: #F2F8FF url('https://p3.ssl.qhimg.com/t11098f6bcdf6fd984c13edaa15.png') no-repeat;
  background-size: contain;
}

.my-swiper {
  width: 1200px;
  border-radius: 16px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;

  .swiper-slide {
    width: 240px !important;
    padding-top: 60px;

    &:first-child {

      .swiper-item-bg,
      .swiper-item {
        border-radius: 16px 0 0 16px !important;
      }
    }

    &:last-child {

      .swiper-item-bg,
      .swiper-item {
        border-radius: 0 16px 16px 0 !important;
      }
    }
  }

  .swiper-shadow {
    height: 360px;
    box-shadow: -12px 0px 23.6px 0px rgba(1, 93, 231, 0.11);
    position: relative;
    z-index: 1;
  }

  .swiper-animation {
    width: 240px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
  }

  .swiper-item-bg {
    border: 1px solid #FFF;
    background: linear-gradient(180deg, #EAF3FF 0%, #DBEBFF 100%);
    height: 360px;
    margin-top: 66px;

    .swiper-item-img {
      height: 360px;
    }

    .swiper-item {
      display: flex;
      height: 220px;
      padding: 60px 32px 80px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      position: absolute;
      bottom: 0;
    }

    .item-content {
      position: relative;
      z-index: 1;

      .item-title {
        color: $color;
        font-family: $font-family-bolid;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 12px;
      }

      .item-intr {
        color: $text-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .item-btn-link {
      display: flex;
      width: 132px;
      height: 32px;
      padding: 4px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #006BFF;
      color: #FFF;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      margin-top: auto;
    }
  }

  .swiper-slide.active {
    width: 480px !important;
    transition: width 0.3s ease-in-out;

    .swiper-animation {
      width: 480px;
      height: 427px;
      transition: width 0.3s ease-in-out;
    }

    .swiper-item {
      padding: 60px 0px 40px 40px;
      height: 260px;
    }

    .swiper-item-bg {

      padding-right: 0;
      background: linear-gradient(180deg, #E5EFFF 0%, #C7DDFF 100%);

      .swiper-item-img {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 480px;
        height: 427px;
      }
    }

    .item-content {
      width: 440px;

      .item-title {
        margin-bottom: 16px;
      }

      .item-intr {
        border-radius: 12px 0px 0px 12px;
        border: 1px solid rgba(255, 255, 255, 0.50);
        background: rgba(255, 255, 255, 0.70);
        backdrop-filter: blur(10px);
        padding: 24px;
      }
    }
  }
}
</style>