
<!--
 * @Author: yh
 * @Date: 2024-07-25 17:25:34
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-07-31 11:14:22
 * @FilePath: \enterprises-service\src\views\Examples\examples.vue
-->
<template>
  <div class="examples-box">
    <div class="module-title title">成功案例</div>
    <div class="swiper-container">
      <swiper @swiper="onSwiper" ref="mySwiper" :modules="[Navigation, Pagination, Autoplay]" :slides-per-view="'auto'"
        :space-between="120" :centered-slides="true" :autoplay="{ delay: 3000, disableOnInteraction: false }"
        :pagination="{ clickable: true }" class="examples-swipper">
        <swiper-slide v-for="item in examplesList" :key="item.id">
          <div class="swiper-item">
            <div>
              <div class="examples-title">{{ item.title }}</div>
              <div class="examples-content">
                {{ item.desc }}
              </div>
            </div>
            <div class="examples-ratio">
              <div class="examples-ratio-item" v-for="(rate, index) in item.rate" :key="index">
                <div class="rate">
                  <div class="num">{{ rate.num }}<span>{{ rate.unit }}</span></div>
                  <img class="icon" src="https://p3.ssl.qhimg.com/t11098f6bcd75599c68865c29f2.png" />
                </div>
                <div class="examples-ratio-name">{{ rate.name }}</div>
              </div>
            </div>
          </div>
          <img class="examples-robot" :src="item.robot" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { onMounted, ref, nextTick } from 'vue';

const examplesList = [
  {
    id: 1,
    title: '智能营销',
    desc: '某大型企业面临产线多、咨询量大、客户等待时间长、满意度下降等问题。360智能客服系统集成多轮对话、AI会话总结、智能语音应答与转接、AI情绪识别技术及AI自动外呼，提升客服效率和用户体验。AI自动外呼用于客户回访、满意度调查和活动通知等场景，进一步优化客户服务流程，减少人工负担。',
    rate: [{ num: 45, unit: '%', name: '客服效率' }, { num: 20, unit: '%', name: '订单转化率' }, { num: 15, unit: '%', name: '客户接通率' }],
    robot: 'https://p2.ssl.qhimg.com/t11098f6bcd673c012a287c7e03.png'
  },
  {
    id: 2,
    title: '幕印',
    desc: '某省计局面临着员工审计知识更新不及时、培训效果难以评估等问题。为了提升员工的专业素质和工作效率，某省计局选择了利用幕印平台来搭建网上课堂，系统化地培训员工审计知识。',
    rate: [{ num: 10, unit: '%', name: '员工考核率' }, { num: 96, unit: '%', name: '考核证书领取率' }, { num: 1.5, unit: '天', name: '协作项目提前完成时间' }],
    robot: 'https://p5.ssl.qhimg.com/t11098f6bcd93b0741ccb61c481.png'
  }
]

const mySwiper: any = ref(null);

const onSwiper = (swiperInstance: any) => {
  mySwiper.value = swiperInstance;
  swiperInstance.update();
  swiperInstance.loopCreate();
  swiperInstance.pagination.update();
};
</script>

<style lang="scss" scoped>
.examples-box {
  height: 510px ;
  margin: 0 auto;
  background: #F8FBFF;
  padding: 100px 0 80px;
  background: #DDEEFF url('https://p0.ssl.qhimg.com/t11098f6bcd15efcfbdb1780a6f.png') no-repeat;
  background-size: contain;

  .title {
    color: #FFF;
    margin-bottom: 17px;
    width: $container-width;
    margin: 0 auto;
  }

  .examples-swipper {
    padding-bottom: 60px;
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    width: 900px !important;
    padding-top: 60px;
  }

  .swiper-item {
    display: flex;
    height: 238px;
    padding: 40px 320px 40px 60px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.90);
    box-shadow: 0px 9px 29.2px 0px rgba(74, 157, 255, 0.12);

    .examples-title {
      color: $color;
      font-family: $font-family;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 12px;
    }

    .examples-content {
      color: $text-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .examples-ratio {
      display: flex;
      gap: 48px;


      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .rate {
          display: flex;
          align-items: center;
          gap: 8px;

          .num {
            color: $color;
            font-family: DIN;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            span {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }

          .icon {
            display: block;
            width: 15px;
            height: 27px;
          }
        }
      }

      &-name {
        color: $text-color;
        margin-top: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  .examples-robot {
    display: block;
    height: 344px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
<style lang="scss">
.examples-swipper .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
}
.examples-swipper .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #fff !important;
  /* 默认颜色 */
  opacity: 0.4 !important;
}

.examples-swipper .swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
  background: #fff !important;
  /* 活动分页器颜色 */
  opacity: 1 !important;
}
</style>
