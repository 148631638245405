<template>
  <div class="page-header">
    <img src="https://p4.ssl.qhimg.com/t11098f6bcd12507a26d16a1775.png" width="128px" height="36px">
    <span class="title">智能企业服务</span>
    <div class="btn-list">
      <span :class="['btn-item', (route.hash == '' || route.hash == '#index') ? 'active' : '']"><a href="#index" @click.prevent="scrollToSection('#index')">首页</a></span>
      <span :class="['btn-item', route.hash == '#product' ? 'active' : '']"><a href="#product" @click.prevent="scrollToSection('#product')">产品中心</a></span>
      <span :class="['btn-item', route.hash == '#cases' ? 'active' : '']"><a href="#cases" @click.prevent="scrollToSection('#cases')">解决方案</a></span>
      <span :class="['btn-item', route.hash == '#examples' ? 'active' : '']"><a href="#examples" @click.prevent="scrollToSection('#examples')">成功案例</a></span>
    </div>
  </div>
</template>
<script  lang="ts" setup>
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();

const scrollToSection = (hash: string) => {
  const element = document.querySelector(hash);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
    // 更新 URL 锚点
    router.replace({ ...route, hash });
  }
};
</script>
<style lang="scss" scoped>
.page-header {
  display: flex;
  width: 1200px;
  padding: 12px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0px 0px 32px 32px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(40px);
  z-index: 999;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  .title {
    color: #1D2531;
    font-family: "CustomFont";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .btn-list {
    .btn-item {
      color: #1D2531;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 150% */
      margin-left: 48px;

      &.active {
        color: #007BFF;
      }
    }

    .btn-item:hover {
      color: #007BFF;
      cursor: pointer;
    }
  }
}
</style>